.hero {
  background-color: #445766 !important;
}

.message-header {
  background-color: #445766 !important;
}

.panel-heading {
  background-color: #445766;
  color: #ffffff;
}
